@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@400;700&display=swap');
html {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Dosis', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

#root {
  height: 100%
}

h1 {
  font-family: 'Dosis', sans-serif;
  

}
